import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function HomeHero() {
	return <StaticImage
		src="../../images/bg/KustomKreationsHome.jpg"
		aspectRatio={16 / 9}
		quality={95}
		objectFit="contain"
		formats={["AUTO", "WEBP", "AVIF"]}
		alt="Steel Fabrication Home Mobile Image"
	/>
}