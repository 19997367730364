import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function LogoV1() {
	return <StaticImage
		src="../../images/logo/KustomKreationsFooterLogo.png"
		height={380}
		width={290}
		quality={95}
		objectFit="contain"
		formats={["AUTO", "WEBP", "AVIF"]}
		alt="Steel Fabrication Home Center Placeholder"
	/>
}