import * as React from 'react'
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import styled from "styled-components"
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'
import Seo from '../components/seo'
import { HomeHero } from '../components/images/HomeHero'
import { LogoV1 } from '../components/images/HomeLogo'
// import HeroImageA from "../components/MainHero"
// import StyledButton from "../components/button"

const Color = styled.div`
  padding: 16px 0;
  background: #839ba6;
`

const HeroWrapper = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;

  grid-row-gap: 32px;
`

const FlexTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 32px;
  }
`

const IndexPage = () => (
  <Layout>
    <Seo
      title="Kustom Kreations Welding HomePage"
      description="Welding and Steel Fabrication Specialists"
    />

    {/* Hero Image is found in /components/MainHero.js */}
    {/* <HeroImageA /> */}

    <HeroWrapper>
      <HomeHero />
      <h1>A Stainless Steel Fabrication &amp; Welding Specialist</h1>
    </HeroWrapper>

    <SiteWidthWrapper>
      <p>
        Kustom Kreations has been in the commercial restaurant and fabrication
        industry since opening its doors in 2005. We are very high skilled in
        what we do and have allot of pride in our products we produce. We have
        recently moved into our new industrial shop in mission, BC. Where some
        of our equipment range from 12’ press brakes to 13’ shears. We are set
        up for Mig, Tig and Stick welding but specialize in Tig welding of
        stainless steel. We have had the pleasure of providing the highest
        quality of craftsman ship to some of greater Vancouver and Fraser
        valleys finest restaurants.
      </p>
    </SiteWidthWrapper>

    <Color>
      <SiteWidthWrapper>
        <FlexTwo>
          <div>
            <LogoV1 />
          </div>
          <div>
            <h2>Specializing In:</h2>
            <ul>
              <li>Restaurants</li>
              <li>Breweries</li>
              <li>Process piping</li>
              <li>Institutional</li>
            </ul>
          </div>
        </FlexTwo>
      </SiteWidthWrapper>
    </Color>

    <SiteWidthWrapper>
      <p>
        We have spent lots of time on the design and fabrications of commercial
        kitchens, recently finishing 2 new projects in fort Langley. At Kustom
        Kreations we are a ASME A112.19.3 / CSA B45.4 certified facility for
        design and fabrication of commercial sinks serving some of the major
        plumbing contractors already. We have experience in the brewery industry
        as well as process piping to sanitary standards. We do large scale to
        small repairs. Feel free to call us with any upcoming projects you might
        have.
      </p>
    </SiteWidthWrapper>
  </Layout>
)

export default IndexPage
